*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    transition: all .3s ease-in-out;
}
body{
    font-family: 'conthrax';
    scroll-behavior: smooth;
}
@font-face{
    src: url('../fonts/Poppins/Poppins-Regular.ttf');
    font-family: 'conthrax';
}
@font-face{
    src: url('../fonts/sofiapro/Fonts/sofiapro-light.otf');
    font-family: 'sofia-pro';
}
@font-face {
    font-family: 'inter';
    src: url('../fonts/Inter/static/Inter-Regular.ttf');
}
@font-face {
    font-family: 'inter-b';
    src: url('../fonts/Inter/static/Inter-Bold.ttf');
}
@font-face {
    font-family: 'inter-sb';
    src: url('../fonts/Inter/static/Inter-SemiBold.ttf');
}
@font-face {
    font-family: 'exo';
    src: url('../fonts/Exo/static/Exo-Regular.ttf');
}
@font-face {
    font-family: 'exo-b';
    src: url('../fonts/Exo/static/Exo-Bold.ttf');
}

.font-inter-sb{
    font-family: 'inter-sb' !important;
}
.font-inter-b{
    font-family: 'inter-b' !important;
}
.font-exo-b{
    font-family: 'exo-b' !important;
}
.font-sofia{
    font-family: 'inter' !important;
}
.p-20px{
    padding: 20px !important;
}
p{
    color: #859099;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
}
h2{
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
    color: #445765;
}
h3{
    font-weight: 600;
    font-size: 36px;
    line-height: 43px;
    color: #445765;
}
h4{
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
}
small{
    font-weight: 600;
    font-size: 16px;
    line-height: 36px;
}
.text-dark-100{
    color: #08213E !important;
}
.text-dark-200{
    color: #020659 !important;
}
.bg-dark-100{
    background-color: #08213E !important;
}
.h-48{
    height: 48px;
}
.w-48{
    width: 48px;
}
.loading-circle{
    border-right: 5px solid #009FE1 !important;
    animation: loading 10s  infinite normal ease-in-out;
    margin: 30px 0;
}
@keyframes loading {
    0%{
        transform: rotate(180deg);
    }
    100%{
        transform: rotate(3600deg);
    }
}
.transition-none{
    transition: unset !important;
}
.container-fluid{
    padding-left:160px;
    padding-right: 160px;
}
.text-light-blue{
    color: #3484B5 !important;
}
.text-light-purple{
    color: #B145BF !important;
}
.text-light-grey{
    color: #445765 !important;
}
.glow-btn{
    border: 1px solid #6ECADC;
    transition: all .3s ease-in-out;
}
.glow-btn:hover{
    animation: glow 1.3s .3s infinite alternate ease-in-out;
}
@keyframes glow {
    0%{box-shadow: 0px 4px 15px rgba(255, 255, 255, 0.54);}
    100%{box-shadow: 0px 4px 28px rgba(255, 255, 255, 0.9);}
}
.blue-btn{
    transition: all .3s ease-in-out;
}
/* .blue-btn:hover{
    background: linear-gradient(88deg, #6ECADC 0%, #08213E 100%);
    color: #fff !important;
} */
.blue-btn:hover span svg path{
    stroke: #fff !important;
}
.bg-grey{
    background-color: #445765 !important;
}
.text-grey{
    color: #445765 !important;
}
.text-capitalize{
    text-transform: capitalize !important;
}
.pointer-none{
    pointer-events: none !important;
}
.f-16px{
    font-size: 16px !important;
    line-height: 24px;
}
.f-18px{
    font-size: 18px !important;
    line-height: 28px;
}
.z-index-2{
    position: relative;
    z-index: 2;
}
/* home page start */
.navbar{
    margin-bottom: 100px;
    padding-top: 36px;
}
.navbar-light .navbar-nav .nav-link span svg path{
    stroke: #000 !important;
}
.navbar-light .navbar-nav .nav-link:hover{
    background-color: #3484B5 !important;
    color: #FFF !important;
}
.navbar-brand img, .navbar-brand-white img{
    max-width: 220px;
}
.navbar-light .navbar-nav .nav-link.active{
    background-color: #3484B5 !important;
    color: #FFF !important;
}
.navbar-light .navbar-nav .nav-link.active span svg path{
    stroke: rgb(255, 255, 255) !important;
}
.navbar-nav .nav-item .nav-link{
    min-width: 120px;
    transition: all .3s ease-in-out;
}
.service-nav:active,
.service-nav:focus{
    pointer-events: none;
    cursor: default;
}
.service-nav:hover,
.service-nav:hover{
    background-color: #3484B5 !important;
    color: #fff !important;
}
.navbar-nav .nav-item .service-nav:hover span svg path{
    stroke:#fff  !important; 
}
.navbar-nav .nav-item .service-nav:hover span svg{
    transform: rotate(-180deg); 
}
.header-layer {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
.header-layer img{
    width: 100%;
}
.navbar-collapse .header-btn{
    padding: 20px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    background: linear-gradient(180deg, #3484B5 0%, #007A93 100%);
}
.navbar-collapse .header-btn span{
    margin-left: 10px;
}
.navbar-nav .nav-link.number:hover{
    background-color: transparent !important;
    color: #08213E !important;

}
.header-absolute .navbar-nav .nav-link.number:hover{
    background-color: transparent !important;
    color: #fff !important;
}
.navbar-nav .nav-link{
    color: #08213E !important;
    padding: 8px 15px !important;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    cursor: pointer;
    margin-right: 3px;
}
.nav-item.dropdown .nav-link::after{
    display: none;
}
.nav-item.dropdown span svg{
    height: 24px;
    width: 24px;
}
.nav-item.dropdown .dropdown-menu-content{
    position: absolute;
    scale: .5;
    top: 44px;
    opacity: 0;
    visibility: hidden;
    list-style: none;
    text-align: start;
    border: 0;
    border-top: 5px solid #3484B5;
    background: #FFF;
    box-shadow: 0px 2.767256498336792px 2.2138051986694336px 0px rgba(0, 0, 0, 0.07), 0px 6.650102138519287px 5.32008171081543px 0px rgba(0, 0, 0, 0.05), 0px 12.521552085876465px 10.017241477966309px 0px rgba(0, 0, 0, 0.04), 0px 22.3363094329834px 17.869047164916992px 0px rgba(0, 0, 0, 0.04), 0px 41.777610778808594px 33.422088623046875px 0px rgba(0, 0, 0, 0.03), 0px 100px 80px 0px rgba(0, 0, 0, 0.02);
    padding: 20px 0px;
    border-radius: 0;
    left: 0;
    right: unset;
    transition: all .3s ease-in-out;
    z-index: 999;
}
.nav-item.dropdown:hover .dropdown-menu-content{
    opacity: 1 !important;
    scale: 1;
    visibility: visible !important;
}
.nav-item.dropdown:hover svg path{
    stroke: #Fff;
}
.nav-item.dropdown .dropdown-menu-content .dropdown-item{
    color: #445765;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 25.722px; /* 160.764% */
    letter-spacing: 0.778px;
    margin-bottom: 25px;
    padding: 0 21px;
    transition: all .3s ease-in-out;
    /* border-left: 5px solid transparent; */
    position: relative;
    cursor: pointer;
}
.nav-item.dropdown .dropdown-menu-content .dropdown-item::before{
    width: 5px;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: #3484B5;
    scale: 0;
    opacity: 0;
    transition: all .3s ease-in-out;
}
.nav-item.dropdown .dropdown-menu-content .dropdown-item:hover{
    padding-left: 26px;
}
.nav-item.dropdown .dropdown-menu-content .dropdown-item:hover::before{
    scale: 1 !important;
    opacity: 1 !important;
}
.dropdown-item.active, .dropdown-item:active{
    background-color: transparent;
}
.banner-left{
    position: relative;
}
.banner-left::before{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: -119px;
    right: 175px;
    background-image: url('../images/thread-layer.svg');
    background-repeat: no-repeat;
    background-size: auto;
    z-index: -1;
}
.watch-btn span{
    border: 2px solid #3484B5;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    /* position: relative;
    overflow: hidden; */
}
.watch-btn:hover span{
    background: #3484B5;
}
/* .watch-btn span::after{
    content: '';
    left: 0;
    top: 0;
    position: absolute;
    width: 0;
    height: 100%;
    background: #3484B5;
    z-index: -1;
    transition: all .5s ease-in-out;
    border-radius: 50%;
}
.watch-btn:hover span::after{
    width: 100%;
} */
.watch-btn:hover svg g path{
    fill: #fff;
}
/* .watch-btn:hover svg{
    fill: #3484B5;
}
.watch-btn:hover svg circle{
    stroke: #3484B5;
} */
.startup::before{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: url('../images/startup-bg.svg');
    background-repeat: no-repeat;
    background-size: auto;
    z-index: 0;
}
.banner-heading{
    font-style: normal;
    font-weight: 600;
    font-size: 74px;
    line-height: 111px;
    color: #445765;
    margin-bottom: 20px;
}
.banner-card-img{
    position: absolute;
    top: -80px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 220px;
}
.banner-card-img img{
    width: 100%;
}
.gradiant-text{
    background:linear-gradient(rgba(110, 202, 220, 1),
    rgba(66, 142, 157, 1));
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}
.gradiant-text-btn{
    background: linear-gradient(89.75deg, #6ECADC 0.13%, #085B6B 86.07%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all .5s ease-in-out;
}
.gradiant-text-btn:hover svg path{
    stroke: #fff;
}
.start-btn{
    border-radius: 10px;
    min-width: 200px;
    border: 1px solid #F4B4B1 !important;
    position: relative;
    transition: all .5s ease-in-out !important;
    background-color: transparent ;
    overflow: hidden;
    color: #08213E;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    z-index: 10;
    text-align: center;
}
.start-btn.white::after{
    background-color: #fff;
}
/* .start-btn svg{
    animation: back-forth 1.2s .7s infinite alternate ease-in-out;
} */
.start-btn span{
    overflow: hidden;
    position: absolute;
    transition: all .3s ease-in-out;
}
.start-btn span svg{
    opacity: 0;
    margin-left: 5px;
    visibility: hidden;
    scale: .7;
    margin-bottom: -50px;
    display: inline-block;
    transition: all .5s ease-in-out;
}
.start-btn:hover span{
    position: relative;
}

.start-btn:hover span svg{
    visibility: visible;
    margin-bottom: 0;
    opacity: 1;
    scale: 1;
    position: static;
}
@keyframes back-forth{
    0%{transform: translateX(0px);}
    100%{transform: translateX(7px);}
}
.start-btn::after{
    content: '';
    left: 0;
    top: 0;
    position: absolute;
    width: 0;
    height: 100%;
    background: #F4B4B1;
    z-index: -1;
    transition: all .3s ease-in-out;
}
.start-btn:hover{
    box-shadow: 2.57179px 10.28716px 32.14736px 0px rgba(0, 0, 0, 0.25) !important;
}
.start-btn:hover::after{
    width: 100%;
}
.start-btn svg path{
    stroke: #08213E;
    fill: none;
}
.start-btn.white svg path{
    stroke: #fff;
}
.start-btn:hover{
    border-radius: 10px;
    border: 1px solid #F4B4B1 !important;
    box-shadow: 2.57179px 10.28716px 32.14736px 0px rgba(0, 0, 0, 0.25);
    color: #fff !important;
}
.start-btn.white:hover{
    border-radius: 10px;
    border: 1px solid #fff !important;
    box-shadow: 2.57179px 10.28716px 32.14736px 0px rgba(0, 0, 0, 0.25);
    color: #000 !important;
}
.start-btn.white svg path{
    stroke: #000;
}
.start-btn:hover svg path{
    stroke: #fff;
}
.start-btn.white.text-white svg path{
    stroke: #fff ;
}
.start-btn.white:hover svg path{
    stroke: #000;
}
.start-btn.contact-submit::after{
    background-color: #445765;
}
.start-btn.contact-submit:hover{
    border-color: #445765 !important;
}
.scrol-btn{
    margin-top: 100px;
    width: fit-content;
    color: #08213E;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24.3px; /* 135% */
}
.scrol-btn span{
    background: #445765;
}
.scrol-btn svg{
    animation: up-down-arrow 1.2s .7s infinite alternate ease-in-out;
}
@keyframes up-down-arrow{
    0%{transform: translateY(0px);}
    100%{transform: translateY(5px);}
}
.scrol-btn span svg path{
    stroke: #fff;
}
.scrol-btn:hover span{
    background-color: #445765;
}
.scrol-btn:hover span svg path{
    stroke: #fff;
}
.scrol-btn a{
    width: fit-content;
}
.working-with{
    margin-bottom: 160px;
    margin-top: 150px;
}
.working-with.services-page-working{
    padding: 100px 0;
    padding-top: 27px;
}
.working-with.trust-leading{
    padding: 120px 0;
}
.working-with.services-page-working h2{
    font-family: 'Inter';
    margin-bottom: 20px;
}
.working-with p{
    color: #445765;
    text-align: right;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px; /* 175% */
}
.working-with .leading-brand h4{
    color: #3484B5;
    font-family: "Inter";
    font-size: 31.546px;
    font-style: normal;
    font-weight: 700;
    line-height: 37.855px; /* 120% */
}
#working-slides .item>div{
    height: 100% !important;
}
#working-slides .item>div img{
    filter: grayscale(1);
    max-width: 260px;
    max-height: 80px;
}
#working-slides .item>div img:hover{
    /* scale: 1.1; */
    filter: grayscale(0);
}
#working-slides .owl-stage{
    display: flex;
    align-items: center;
}
#working-slides .item>div img{
    width: 100% !important;
}
.working-with p{
    font-size: 24px;
}
.video-background{
    position: absolute;
    width: 100%;
    height: 105%;
    top: -35px;
    left: -40px;
    background: #66ADD9;
    border-radius: 30px 30px 30px 200px;
    z-index: -1;
    box-shadow: 0px 2.76726px 2.21381px 0px rgba(116, 206, 224, 0.35), 0px 6.6501px 5.32008px 0px rgba(116, 206, 224, 0.25), 0px 12.52155px 10.01724px 0px rgba(116, 206, 224, 0.21), 0px 22.33631px 17.86905px 0px rgba(116, 206, 224, 0.17), 0px 41.77761px 33.42209px 0px rgba(116, 206, 224, 0.14), 0px 100px 80px 0px rgba(116, 206, 224, 0.10);
}
.about-img .play-btn{
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%);
}
.play-btn img{
    border-radius: 0 !important;
}
.about-img img{
    border-radius: 0px 0px 0px 228px;
}
.about-us .gradiant-text.about{
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    margin-bottom: 30px;
}
.about-us .gradiant-text.about img{
    width: 40px;
}
.about-us-text p{
    margin-bottom: 37px;
    font-size: 19px;
}
.about-us-text h2 h2{
    margin-bottom: 0;
}
.startup{
    padding: 100px 0;
    margin: 100px 0 0 0 ;
    background: #F1F8FE;
    color: #000 !important;
    position: relative;
}
.startup .btn{
    position: relative;
    z-index: 1;
    width: fit-content;
}
.featured-services .laptop-animation{
    animation: laptop-animate 1.5s 1s infinite alternate ease-in-out;
}
@keyframes laptop-animate {
    0%{transform: translateY(0px);}
    100%{transform: translateY(-10px);}
}
.featured-services{
    background-image: url('../images/light-blue-bg.svg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 100px 0;
}
.featured-services .main-heading{
    margin-bottom: 60px;
}
.featured-services .card{
    padding: 50px 28px  30px 30px;
    transition: all .3s ease-in-out !important;
    border: 0;
    background-color: transparent;
    height: 100%;
    border-radius: 20px !important;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.featured-services .card a span svg path{
    stroke: #3484B5;
}
.featured-services .card:hover .arrow-icon svg path{
    stroke: #000;
}
.featured-services .card-img-home{
    width: 75px;
    height: 75px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
.featured-services .card .card-img-home svg path{
    fill: #3484B5;
    width: 42px;
}
.featured-services .card .card-img svg{
    fill: #3484B5;
    margin-bottom: 55px;
}
.featured-services .card:hover{
    background: #F0F0F0;
    box-shadow: 0px 2.76726px 2.21381px 0px rgba(0, 0, 0, 0.19), 0px 6.6501px 5.32008px 0px rgba(0, 0, 0, 0.14), 0px 12.52155px 10.01724px 0px rgba(0, 0, 0, 0.11), 0px 22.33631px 17.86905px 0px rgba(0, 0, 0, 0.09), 0px 41.77761px 33.42209px 0px rgba(0, 0, 0, 0.08), 0px 100px 80px 0px rgba(0, 0, 0, 0.05); 
}
.featured-services .card:hover .card-img svg{
    fill: #3484B5;
}
.featured-services .card:hover .card-img-home svg path{
    fill: #3484B5;
}
.featured-services .card:hover p,
.featured-services .card:hover h4{
    color: #000 !important;
}
.featured-services .card span p{
    color: #445765 !important;
    font-size: 22px !important;
}
.featured-services .card span.arrow-icon svg path{
    stroke: #445765;
}
.featured-services .services-img{
    text-align: center;
    margin: 100px 0 100px 0;
}
.featured-services .services-img img{
    max-width: 980px;
    margin: 0 auto;
}
.featured-services .services-card-main{
    max-width: calc(25% - 10px);
    width: 100%;
}
.industry .industry-boxes{
    display: flex;
    align-items: center;
    gap: 1.3%;
    margin-top: 80px;
    justify-content: center;
}
.industry .industry-boxes .boxes{
    width: 207px;
    height: 207px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
    border-radius: 15px;
    background: #fff;
    box-shadow: 8px 34px 75px 0px rgba(15, 14, 14, 0.25);
    margin-bottom: 20px;
}
.industry .industry-boxes .boxes h4{
    color: #445765;
    text-align: center;
    font-family: "Exo";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 37.5px; /* 156.25% */
    text-transform: capitalize;

}
#industrySlides .owl-stage-outer{
    height: 100%;
}
#industrySlides .owl-stage-outer .owl-stage{
    height: 100%;
}
#industrySlides .owl-stage-outer .owl-stage .owl-item{
    height: 100%;
}
.industry-overlay,
.industry-overlay-tab{
    position: relative;
}
.industry-overlay-text{
    position: absolute;
    bottom: 20px;
    left: 16px;
    color: white;
}
.industry-overlay-tabs::before{
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(8, 33, 62, 0) 0%, #08213E 100%);
    position: absolute;
    top: 0;
    left: 0;
}
.industry-overlay::before{
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(8, 33, 62, 0) 0%, #08213E 100%);
    position: absolute;
    top: 0;
    left: 0;
}
.industry img{
    width: 60px;
    height: 60px;
    object-fit: cover;
}
.products{
    padding: 100px 0;
    padding-top: 80px;
    /* margin: 100px 0; */
    background-image: url('../images/wave-bg-2.svg');
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
}
.products.services-product{
    background-image: url('../../assets//images/cloud-native-bg.png');
    background-size: cover;
    padding: 200px 0 300px 0;
    margin-bottom: 100px;
}
.products .product-graph{
    margin: 70px 0;
    /* display: flex; */
    align-items: start;
    /* gap: 86px; */
    /* flex-wrap: wrap; */
    justify-content: center;
}
.product-graph .product-img{
    height: 140px;
    width: 140px;
    border-radius: 70px;
    background: #FFF;
    box-shadow: 8px 34px 75px 0px rgba(15, 14, 14, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 auto;
}
.product-graph .product-img .product-img-num{
    width: 30px;
    height: 30px;
    border-radius: 15px;
    position: absolute;
    bottom: -6px;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E8E8E8;
    background: #FFF;
    box-shadow: 8px 34px 75px 0px rgba(15, 14, 14, 0.25);
    color: #08213E;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 19.2px; /* 120% */
}
.products p{
    color: #859099;
    text-align: center;
    font-family: "Inter";
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 42px; /* 150% */
    margin: auto;
}
.products .main-heading  p.heading{
    opacity: .7;
    color: #859099;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 180% */
    max-width: none;
}
.mainProjectBox{
    transition: all .3s ease-in-out;
}
.mainProjectBox:hover img{
    box-shadow: 0px 25px 40px 0px rgba(0, 0, 0, 0.20);
}
.mainProjectBox:hover span.heading{
    color: #3484B5;
}
.mainProjectBox:hover small{
    font-weight: 600;
}
.mainProjectBox.static .project-boxes{
    background: radial-gradient(48.68% 64.48% at 50% -3.1%, #5A5247 0%, #35302B 100%);
}
/* .mainProjectBox .project-boxes-image-main{
    min-height: 300px;
    max-width: 450px;
} */
.mainProjects .mainProjectBox .project-boxes-image-main img{
    width: 100%;    
    /* height: 300px; */
    object-fit: contain;    
}
.mainProjectBox.static .project-boxes-image-main{
    max-width: none;
    min-height: unset;
}
.mainProjectBox.static .project-boxes-image-main img{
    height: 100%;
    object-fit: cover;
}
.project{
    padding-top: 70px;
}
.mainProjectBox.static .project-boxes{
    margin-bottom: 6px;
    /* padding-bottom: 38px; */
    position: relative;
    border-radius: 30px;
    transition: all .3s ease-in-out;
}
.project-boxes img{
    width: 100%;
}
.mainProjectBox.static .project-boxes-image-main{
    filter: grayscale(1);
    transition: all .3s ease-in-out;
}
.project-boxes-image-main,
.project-boxes-image-main img{
    border-radius: 5px;
}
.mainProjectBox.static .project-boxes-image-main img{
    width: 100%;
    height: 100%;
    border-radius: 30px;
    box-shadow: 0px 25px 65px 0px rgba(0, 0, 0, 0.10);
}
.project .project-boxes-inner{
    padding: 30px;
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    width:100%;
    border-radius: 0px 0px 30px 30px;
    background: rgba(0, 0, 0, 0.70);
}
.project-boxes:hover .project-boxes-image-main{
    filter: grayscale(0);
}
.project-inner-main{
    margin-top: 20px;
}
.project-inner-main small{
    color: #445765 !important;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 187.5% */
}
.project-inner-main span.heading{
    color: #445765;
    font-family: "Inter";
    display: block;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28.148px; /* 140.74% */
    text-transform: capitalize;
    text-decoration: none;
    cursor: pointer;
}
.testimonial{
    margin: 100px 0;
    padding: 100px 0;
    /* background-color: rgba(85, 224, 255, 0.04); */
    background-image: url('../images/wave-bg-3.svg');
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
}
.quote-img img{
    width: 75px !important; 
}
.slider-topborder{
    width: 50%;
    height:6px;
    background-color: #3484B5;
    margin-bottom: 55px;
    border-radius: 30px;
}
#testimonial-slides .owl-dots {
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
}
#testimonial-slides .owl-dots .owl-dot span{
    display: block;
    border-radius: 50%;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    background-color: rgba(27, 49, 75, 0.37);
}
#testimonial-slides .owl-dots .owl-dot.active span{
    width: 55px;
    border-radius: 20px;
    background-color: #3484B5;
}
.testimonial .item small{
    color: #859099;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 186.5%; /* 33.57px */
}
.contact-us.blue{
    background-color: #009FE1;
    padding: 80px 0;
    margin-bottom: 0;
}
.contact-left{
    background-color: #FFF;
    border-radius: 30px;
    padding: 85px 77px;
    color: #445765;
    padding-right: 50px;
}
.contact-left small{
    opacity: .5;
}
.contact-left .location{
    margin-top: 215px;
}
.contact-right{
    padding: 40px;
    border-radius: 20px;
    background: #F9F9F9;
    max-width: 833px;
    margin: 0 auto;
}
.contact-right .form-control{
    padding: 23px;
    font-weight: 600;
    font-size: 18.0025px;
    line-height: 22px;
    border-radius: 10px;
    border: 1.286px solid #EFF0FE;
    background: #FFF;
    margin-bottom: 20px;
}
.contact-right textarea.form-control{
    max-height: 200px;
    resize: none;
}
.contact-us .form-control::placeholder{
    color: #08213E;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    opacity: .3;
}
.contact-right button[type=submit]{
    width: 100%;
    padding: 20px 26px;
    border-radius: 10px;
    font-weight: 600;
}
.contact-right button[type=submit] span{
    margin-left: 10px;
}
.contact-right button[type=submit] span svg path{
    stroke: #000 !important;
    fill: none;
}
.contact-right button[type=submit]:hover span svg path{
    stroke: #fff !important;
    fill: none;
}
.contact-left .location p{
    margin-bottom: 33px;
    color: #445765;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.contact-us{
    margin-bottom: 100px;
}
.project-boxes-inner h4{
    color: #FAFAFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 41.944px; /* 174.767% */
    letter-spacing: 1.456px;
    text-transform: uppercase;
}
.project-boxes-inner a{
    font-weight: 600;
    font-size: 24px;
    line-height: 42px !important;
    line-height: 1.5px;
    display: block;
    text-decoration: none;
    width: fit-content;
    text-transform: uppercase;
}
.fixed-div {
  position: relative; 
  transition: all 0.5s ease-in-out; 
}
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, .4);
  z-index: 20;
}
/* footer css */
footer{
    padding: 100px 0 0 0;
    /* background:linear-gradient(94deg, #212121 0.21%, #020659 100%); */
    background: #F1F8FE;
    position: relative;
    color: #445765 !important;
    position: relative;
}
.footer-bg{
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
}
.footer-bg img{
    width: 100%;
}
footer .footer-list li a{
    margin-bottom: 24px;
    color: #445765 !important;
    display: inline-block;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.777778px;
    font-family: 'inter-b';
    text-wrap: wrap;
}
footer .footer-list li:last-child{
    margin-bottom: 0;
}
footer .list-icon{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-bottom: 22px;
}
footer .footer-list p{
    color: #445765;
    font-family: "Inter-b";
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
    line-height: 25.722px; /* 116.919% */
    letter-spacing: 0.014px;
    text-transform: uppercase;
    margin-bottom: 33px;
    position: relative;
    width: fit-content;
}
footer .footer-list p::before{
    content: '';
    height: 3px;
    width: 60%;
    background-color: #3484B5;
    position: absolute;
    bottom: -12px;
    left: 0;
    transition: all .3s ease-in-out;
}
.footer-list-main:hover .footer-list p::before{
    width: 100%;
}
footer .footer-logo img{
    max-width: 272px;
    width: 100%;
}
footer .footer-list-main{
    padding-left: 70px;
    border-left: 1px solid rgba(42, 42, 42, .3);
    height: 100%;
}
.footer-list-main ul div:last-child li a{
    margin-bottom: 0 !important;
}
.footer-left .row{
    margin-left: 90px;
}
.footer-right small{
    font-weight: 400;
    font-size: 15px;
    line-height: 29px;
    display: block;
    margin:30px 0 130px 0;
}
.footer-right>div{
    max-width: 416px;
}
.footer-right>div img{
    width: 100%;
}
.footer-right p{
    color: #445765;
    font-size: 16.667px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.667px; /* 100% */
    letter-spacing: 0.278px;
}
.footer-right .contect-p{
    display: flex;
    color: #445765 !important;
}
footer .social-icon{
    gap: 33px;
}
.social-icon svg{
    fill: rgba(68, 87, 101, 1);    
    transition: none !important;
}
.social-icon span:hover svg path{
    fill: #3484B5;
}
.footer-bottom{
    margin-top: 51px;
    border-top: 1px solid rgba(169, 168, 168, 0.35);
    background: #F1F8FE;
    padding: 23px 0;
    z-index: 2;
    position: relative;
}
.footer-vertical-text{
    position: absolute;
    writing-mode: vertical-lr;
    left: 40px;
    top: 0;
    height: 100%;
    text-align: center;
    padding: 25px;
    background: #0B2D55 !important;
}
.copyright{
    color: #859099;
    font-size: 19.444px;
    font-style: normal;
    font-weight: 400;
    line-height: 33.333px; /* 171.429% */
    letter-spacing: 0.778px;
}
.footer-list-main .footer-list ul li a{
    transition: all .3s ease-in-out;
    position: relative;
    cursor: pointer;
}
.footer-list-main .footer-list ul li a::before{
    width: 5px;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    left: -26px;
    background-color: #3484B5;
    scale: 0;
    opacity: 0;
    transition: all .3s ease-in-out;
}
/* .footer-list-main .footer-list ul li a:hover{
    padding-left: 26px;
} */
.footer-list-main .footer-list ul li a:hover::before{
    scale: 1 !important;
    opacity: 1 !important;
}
/* about page css */
.about-banner-img{
    margin-top: -10%;
}
.about-banner-img img{
    width: 100%;
}
.about-banner .about-counter{
    border-right: 5px solid #DCDCDC;
}
.vision{
    padding: 100px 0;
    padding-top: 130px;
}
.vision .vision-imgs img{
    max-height: 450px;
    max-width: 550px;
    margin: 0 auto;
    display: block;
}
.vision-block {
    margin-bottom: 55px;
}
.vision-block h2{
    cursor: pointer;
}
.vision-block h4{
    color: #445765;
    font-family: "Inter";
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 142.857% */
}
.steps-img img{
    height: 85%;
}
.absolute-img{
    position: absolute;
    top: -140px;
    right: -30px;
    z-index: -1;
}
.absolute-img img{
    width: 100%;
}
.team{
    background-color: #f1f8fe;
    padding: 100px 0;
    margin-bottom: 100px;
}
.team .team-holder-main{
    display: flex;
    justify-content: space-between;
}
.team .team-holder-main .team-holder{
    margin-bottom: 40px;
}
.team-holder .team-holder-img{
    position: relative;
    border-radius: 50%;
    width: 300px;
    height: 300px;
    margin: auto;
}
.team-holder .team-ovelay{
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background-color: #3484B5;
    position: absolute;
    top: 30px;
    right: 10px;
    transition: all .4s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
.team-holder .team-role h4{
    color: #08213E;
    font-family: "Inter";
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 142.857% */
    margin-bottom: 0;
}
.team-holder .team-role{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all .3s ease-in-out;
    width: 100%;
}
.team-holder:hover .team-ovelay{
    width: 100%;
    height: 100%;
    opacity: 1;
    scale: 1;
    right: 0;
    top: 0;
}
.team-holder:hover .team-role{
    opacity: 1;
    z-index: 11;
}
.team-holder:hover .team-ovelay h4{
    display: block;
    opacity: 1;
}
.team-holder-main .team-holder img{
    border-radius: 50%;
    width: 100%;
}
.purple-z-logo{
    max-width: 322px;
    margin-left: auto;
}
.purple-z-logo img{
    width: 100%;
}
/* services page css */
.navbar.navbar-services .nav-link{
    color: #fff !important;
}
.services-banner{
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    position: relative;
    background-position: center;
    background-color: rgba(0, 0, 0, .3);
}
.servce-banner-content{
    padding: 24% 0;
    position: relative;
}
.servce-banner-content-inner.left{
    text-align: start;
}
.servce-banner-content-inner.center{
    text-align: center;
}
.servce-banner-content-inner.right{
    text-align: end;
}
.servce-banner-content p{
    color: #FFF;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
}
.services-banner-heading{
    color: #FFF;
    font-family: "Inter";
    margin-bottom: 20px;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: 72px;
}
.services-heading{
    padding-top: 60px;
}
.services-heading h4{
    margin-bottom: 0;
    cursor: pointer;
}
.services{
    padding: 100px 0;
}
.services img{
    width: 100%;
    max-width: 695px;
}
.service h2{
    text-transform: capitalize;
}
.service-spec{
    padding: 113px 0 93px 0;
    background-color: #FFf;
}
.service-spec h2{
    font-family: 'inter';
    text-transform: capitalize;
    margin-bottom: 24px !important;
}
.service-spec .services-z-img img{
    width: 100%;
    margin: 0 auto;
}
.award-win{
    padding: 163px 0 130px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.award-win h2{
    font-family: 'Inter';
    padding-bottom: 0 !important;
    margin-bottom: 10px !important;
}
.award-win p{
    font-family: 'Inter';
    margin-bottom: 60px !important;
}
.service-spec-box{
    padding: 36px 40px;
    padding-top: 81px;
    border: 1.434px solid #D7D7D7;
    background: #f9f9f9;
    height: 100%;
}
.service-spec-box ul li{
    color: #859099;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
}
.service-process{
    padding: 150px 0 300px 0;
    background-image: url('../images/procces-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    margin: 100px 0;
    margin-top: 0;
}
.service-process h2{
    font-family: 'Inter';
}
.service-process.bg-light-grey{
    background-image: url('../images/procces-white-bg.svg');
}
.service-process.ui-ux-service-process{
    background-image: none;
    background-color: #f1f8fe;
    padding: 120px 0;
    margin: 0 ;
}
.service-process.ui-ux-service-process h2{
    font-family: 'Inter';
    margin-bottom: 15px !important;
    line-height: 72px;
}
.service-process.ui-ux-service-process h4{
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    margin-bottom: 30px !important;
}
.service-process.ui-ux-service-process p{
    font-family: 'Inter';
    font-size: 22px;
    font-weight: 400;
    line-height: 38px;
}
.service-process .process-img{
    max-width: 650px;
    /* max-height: 650px; */
    margin: 0 auto;
}
.service-process .process-boxes{
    padding: 36px 40px;
    padding-top: 61px;
    border: 1.434px solid #D7D7D7;
    background: #fff;
    height: 100%;
}
.service-process .process-boxes h4{
    font-weight: 700;
    line-height: 37.855px;
}
.service-process .process-boxes ul{
    margin-left: 0;
    margin-top: 21px;
}
.service-process .process-boxes ul li{
    color: #000;
    font-size: 21.509px;
    font-family: 'Inter';
    font-weight: 400;
    line-height: 32.98px; /* 153.333% */
}
.flexible{
    padding: 158px 0 97px 0;
    margin-bottom: 130px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: #fff !important;
}
.flexible h2{
    font-family: 'Inter';
    font-size: 32px;
    font-weight: 700;
    line-height: 38.4px; /* 120% */
}
.flexible p{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 21.818px;
    line-height: 37.818px;
}
.idea-reality{
    margin: 80px 0;
    margin-top: 0;
    padding: 100px 0 85px 0;
}
.idea-reality h2{
    text-transform: capitalize;
}
.idea-reality h2,
.idea-reality p{
    color: #fff !important;
    font-family: 'Inter';
}
.idea-reality p{
    margin: 20px 0 40px 0;
}
.software-shipment{
    padding-top: 130px;
    padding-bottom: 50px;
}
.software-shipment h4{
    color: #3484B5;
    font-family: 'Inter-b';
    font-size: 31.553px;
    font-style: normal;
    line-height: 46.864px; /* 148.523% */
}
.leaders{
    background-image: url('../../assets/images/leaders-bg.png');
    padding: 330px 0 210px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 120px;
}
.language-logos{
    padding: 80px 0;
}
.mobile-app{
    padding: 80px 0 178px 0;
    background-color: #f0f0f0;
}
.mobile-app .mobile-app-img{    
    text-align: center;
}
.mobile-app .mobile-app-img img{
    max-width: 420px;
    margin: 0 auto;
}
.strategy{
    padding:127px 0 100px 0;
}
.strategy .row h2,
.strategy .row p{
    font-family: 'Inter';
}
.strategy .strategy-tag-holder{
    justify-content: center;
    margin-top: 120px;
}
.strategy .strategy-tag-holder >div{
    color: #859099;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; 
    margin-bottom: 26px;
}
.strategy .strategy-tag-holder >div span{
    margin-right: 20px;
}
.dev-portfolio{
    background-image: url('../../assets/images/dev-portfolio-bg.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 148px 0 180px 0;
    margin-bottom: 100px;
    position: relative;
}
.dev-portfolio #devportfolio-slides {
    position: static;
}
.dev-portfolio #devportfolio-slides .owl-nav{
    position: absolute;
    right: 0;
    top: 198px;
}
.dev-portfolio #devportfolio-slides .owl-nav button{
    width: 75px;
    height: 75px;
    border: 1px solid #3484B5;
    border-radius: 50%;
    margin-right: 30px;
    color: #3484B5;
    transition: all .2s ease-in-out;
}
.dev-portfolio #devportfolio-slides .owl-nav button:hover{
    color: #445765;
    border-color: #445765;
}
.dev-portfolio #devportfolio-slides .owl-nav button span{
    font-size: 70px;
    line-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dev-portfolio #devportfolio-slides .item .portfolio-layes-main .portfolio-img-layes{
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.85);
    position: absolute;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all .3s ease-in-out;
    visibility: hidden;
    border-radius: inherit;
}
.dev-portfolio #devportfolio-slides .item .portfolio-layes-main:hover .portfolio-img-layes{
    opacity: 1;
    scale: 1;
    visibility: visible;
}
.dev-count{
    padding: 120px 0 50px 0;
    padding-bottom: 25px;
}
.dev-count .count-boxes{
    padding: 26px 5px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #D3D3D3;
    background: #F4F4F4;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    height: 100%;
}
.dev-count .count-boxes p{
    color: #151515;
    font-family: "Exo";
    max-width: 148px;
    margin-bottom: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}
.dev-count .count-boxes h2{
    color: #151515;
    margin-bottom: 0;
    font-family: "Exo";
    font-size: 47px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px; /* 102.128% */
}
.tech-weUse{
    padding: 262px 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 120px;
    /* min-height: 1300px; */
    display: flex;
    align-items: center;
}
.tech-weUse.techuse-white{
    color: #fff !important;
    padding: 96px 0 115px 0 !important;
    margin-bottom: 0;
}
.tech-weUse.techuse-white .tab-btn,
.tech-weUse.techuse-white h2,
.tech-weUse.techuse-white .tech-boxes h4{
    color: #fff !important;
}
.tech-weUse {
    margin-bottom: 50px;
}
.tech-weUse .tab-contents .tech-main{
    display: flex;
    align-items: flex-start;
    gap: 40px;
    flex-wrap: wrap;
    justify-content: center;
}
.tech-weUse .tab-btn-main{
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 848px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 75px;
}
.tech-weUse .tab-btn-main .tab-btn.active{
    font-weight: 700;
    color: #3484B5;
    border-bottom: 6px solid #3484B5;
}
.tech-weUse .tab-btn-main .tab-btn{
    max-width: 50%;
    width: 100%;
    cursor: pointer;
    color: #151515;
    font-family: "Exo";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px; /* 150% */
    text-transform: capitalize;
    border-bottom: 1px solid #000;
    padding-bottom: 25px;
}
.tech-weUse .tab-contents .tech-main .tech-boxes{
    text-align: center;
    max-width: 122px;
    width: 100%;
}
.tech-weUse .tab-contents .tech-main .tech-boxes h4{
    margin-top: 8px;
    margin-bottom: 0;
    color: #151515;
    font-family: "Exo";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 37.5px; /* 156.25% */
    text-transform: capitalize;
}
.tech-weUse .tab-contents .tech-main .tech-boxes-img{
    width: 122px;
    height: 128px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #FFF;
}
.need-deed{
    padding: 80px 0 83px 0;
    background-color: #F1F8FE;
}
.need-deed .deed-cards{
    text-align: center;
}
.need-deed h2,
.need-deed p{
    font-family: 'Inter';
}
.need-deed .deed-cards h4{
    color: #445765;
    text-align: center;
    margin: 30px 0 15px 0;
    font-family: "Inter";
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 37.5px; /* 133.929% */
    text-transform: capitalize;
}
.custom-solutions{
    padding: 120px 0;
}
.custom-solutions h2{
    font-family: 'Inter';
}
.custom-solutions .custom-solutions-boxes-main{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 34px;
    /* flex-wrap: wrap; */
}
.custom-solutions .custom-solutions-boxes-main .custom-solutions-boxes{
    width: 192px;
    height: 192px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 13.946px;
    border: 0.93px solid #C8C8C8;
    background: #FFF;
}
.custom-solutions .custom-solutions-boxes-main .custom-solutions-boxes p{
    color: #445765;
    font-family: 'Inter';
    margin-bottom: 0;
    margin-top: 17px;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 34.866px; /* 174.328% */
    text-transform: capitalize;
}
.logo-boxes-main{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}
.logo-boxes-main .logo-boxes{
    width: 130px;
    height: 135px;
    border-radius: 10px;
    background: #F9F9F9;
    display: flex;
    justify-content: center;
    align-items: center;
}
.emerging-tech{
    padding: 112px 0 100px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('../../assets/images/emerging-tech-bg.png');
}
.emerging-tech small{
    color: #FFF;
    font-family: "Exo";
    margin-bottom: 7px;
    font-size: 12.8px;
    font-style: normal;
    font-weight: 700;
    line-height: 19.2px; /* 150% */
    letter-spacing: 3.354px;
    text-transform: uppercase;
}
.emerging-tech h2,
.emerging-tech p{
    color: #fff !important;
    font-family: 'Inter';
}
.ecommerce-success{
    padding: 79px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('../../assets/images/ecom-success-bg.png');   
    margin-bottom: 120px;
}
.blockchain-product{
    padding: 80px 0 ;
    margin-bottom: 120px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('../../assets/images/blockchain-product-bg.png');
}
.blockchain-product p{
    color: #FFF;
    font-family: "Inter";
    margin-bottom: 0;
    margin-top: 25px;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 42px; /* 150% */
}
.zodiac-blockchain{
    padding: 32px 0;
    background-color: #F1F8FE;
}
.work-tech{
    padding: 80px 0 90px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('../../assets/images/work-tech-bg.png');
}
.work-tech h4{
    border-bottom: 5px solid #fff;
    margin-bottom: 0;
    color: #FFF;
    font-family: "Exo";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 38.625px; /* 160.938% */
    text-transform: capitalize;
}
.work-tech h4,
.work-tech h3,
.work-tech p{
    color: #fff !important;
}
.blockchain-dev-opt{
    padding: 120px 0 100px 0;
}
.blockchain-dev-opt .dev-opt-mian{
    padding: 24px 60px 12px 60px;
    border-radius: 10px;
    border: 1px solid #C1BBBB;
    background: #F4F4F4;
    box-shadow: 8px 34px 75px 0px rgba(15, 14, 14, 0.25);
}
.blockchain-dev-opt .dev-opt-mian h4{
    color: #445765;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 72px; /* 300% */
}
.ecom-services{
    padding: 0 0 120px 0;
}
.ecom-services .deed-cards h4{
    color: #445765;
    font-family: "Inter";
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 37.5px; /* 133.929% */
    text-transform: capitalize;
    position: relative;
    padding-bottom: 15px;
    margin-top: 45px;
    margin-bottom: 26px;
}
.ecom-services .deed-cards h4::after{
    max-width: 160px;
    width: 100%;
    height: 3px;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #3484B5;
}
.why-zodiac{
    padding: 120px 0;
}
.why-zodiac .row h2{
    color: #3484B5;
    font-family: 'Inter';
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px; /* 100% */
    margin-bottom: 10px;
}
.why-zodiac .row p{
    color: #151515 !important;
    text-align: center;
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 30px; /* 166.667% */
    margin-bottom: 0 !important;
}
.cloud-services{
    padding: 100px 0;
    background: linear-gradient(180deg, #3432AB 0%, #6707AC 100%);
}
.cloud-services .container-fluid{
    padding-left: 100px;
    padding-right: 100px;
}
.cloud-services .cloud-boxes{
    padding: 40px 0 59px 0;
    text-align: center;
    height: 100%;
}
.cloud-services .cloud-boxes.one,
.cloud-services .cloud-boxes.three,
.cloud-services .cloud-boxes.two{
    border-bottom: 1px solid #C5C5C5;
}
.cloud-services .cloud-boxes.one,
.cloud-services .cloud-boxes.two,
.cloud-services .cloud-boxes.four,
.cloud-services .cloud-boxes.five{
    border-right: 1px solid #C5C5C5;
}
.cloud-services .cloud-boxes.four,
.cloud-services .cloud-boxes.five,
.cloud-services .cloud-boxes.six{
    padding-bottom: 0;
}
.cloud-boxes h4{
    color: #FFF;
    text-align: center;
    font-family: "Exo";
    margin: auto;
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px; /* 171.429% */
    max-width: 340px;
}
.cloud-benefit{
    padding: 92px 0 80px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('../../assets/images/cloud-benefit-bg.png');
}
.cloud-benefit h2{
    font-family: Exo;
    font-size: 44.961px;
    font-style: normal;
    font-weight: 700;
    line-height: 67.441px; /* 150% */
    text-transform: capitalize;
}
.cloud-benefit .cloud-benifit-boxes{
    margin-left: 20px;
}
.cloud-benefit .cloud-benifit-boxes h4::before{
    content: '';
    width: 7px;
    height: 36px;
    position: absolute;
    left: -20px;
    top: 50%;
    background-color: #fff;
    transform: translateY(-50%);
}
.cloud-benefit .cloud-benifit-boxes h4{
    color: #FFF;
    position: relative;
    margin-bottom: 10px;
    font-family: "Inter";
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 39.341px; /* 140.503% */
    text-transform: capitalize;
}
.cloud-benefit .cloud-benifit-boxes p{
    color: #FFF;
    margin-bottom: 0;
    font-family: "Exo";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 31.847px; /* 159.236% */
}
.cloud-tech{
    padding: 120px 0;
}
.cloud-tech .cloud-tech-card{
    border: 1.229px solid #EAE9F2;
    background: #F9F9F9;
    padding: 33px 36px 44px 36px;
    height: 100%;
    margin-bottom: 28px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.cloud-tech .cloud-tech-card h4{
    color: #445765;
    font-family: "Exo";
    font-size: 22.311px;
    font-style: normal;
    font-weight: 700;
    line-height: 66.932px; /* 300% */
    text-transform: capitalize;
    margin-bottom: 0;
}
.cloud-tech .cloud-tech-card p{
    color: #859099;
    margin: 20px 0 16px 0;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 180% */
}
.cloud-tech .cloud-tech-card .cloud-card-img{
    width: 72px;
    height: 72px;
    border: 1px solid #EAE9F2;
    display: flex;
    align-items: center;
    justify-content: center;
}
.logo-modal .main-img{
    width: 100%;
    height: 100%;
    max-width: 1240px;
    max-height: 570px;
}
.logo-modal .main-img img{
    object-fit: cover;
    height: 100%;
    max-height: 570px;
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;
    display: block;
    margin-bottom: 30px;
    transition: all .3s ease-in-out;
}
.logo-modal .modal-nav{
    width: 64px;
    height: 64px;
    border-radius: 5px;
    background: #8F8F8F;
    box-shadow: 0px 0px 11.94522px -5.97261px rgba(0, 0, 0, 0.32);
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo-modal .modal-nav:hover{
    background-color: #3484B5;
}
.logo-modal .logo-image-preview{
    max-width: 1240px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    gap: 40px;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}
.logo-image-preview img.active{
    border: 5px solid #3484B5;
}
.logo-modal .logo-image-preview img{
    max-width: 278px;
    max-height: 203px;
    width: 100%;
    height: 203px;
    object-fit: cover;
    cursor: pointer;
}
.web-working-process{
    background-image: url('../images/web-process-bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 105px 0 72px 0;
}
.web-working-process .web-process-count-main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;
    position: relative;
}
.web-process-count-main::before{
    content: '';
    width: 90%;
    height: 1px;
    background-color: #fff;
    position: absolute;
    top: 48%;
    transform: translateY(-50%);
    left: 27px;
}
.web-working-process .web-process-count-main .web-process-count small{
    color: rgba(255, 255, 255, .6);
    text-align: center;
    font-family: "Exo";
    font-size: 25.354px;
    font-style: normal;
    font-weight: 700;
    line-height: 27.659px; /* 109.091% */
    text-transform: capitalize;
}
.web-working-process .web-process-count-main .web-process-count h2{
    color: rgba(255, 255, 255, .6);
    font-family: "Exo";
    font-size: 66px;
    font-style: normal;
    font-weight: 700;
    line-height: 72px; /* 109.091% */
    text-transform: capitalize;
    margin-bottom: 10px;
    width: fit-content;
    position: relative;
}
.web-working-process .web-process-count-main .web-process-count h2::before{
    content: '';
    width: 17px;
    height: 17px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}
.web-working-process .web-process-count-main .web-process-count p{
    margin-bottom: 0;
    color: #FFF;
    font-family: "Exo";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 150% */
}

/* case-detail css */
.absolute-circle{
    width: 160px;
    height: 160px;
    background-color: rgba(116, 206, 224, 0.28);
    border-radius: 50%;
    position: absolute;
    top: -53px;
    left: -60px;
    z-index: -1;
}
.service-spec h4,
.service-process h4{
    color: #445765;
    font-family: "Inter";
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 47px; /* 257.143% */
    margin-bottom: 37px !important;
}
.service-process h4{
    font-size: 32px;
    line-height: 48px;
}
.service-spec p{
    font-family: 'Inter';
}
.client-industries{
    background: linear-gradient(180deg, #3484B5 0%, #1B75BB 100%);
    border-radius: 0px 44.5186px 44.5186px 0px;
    width: 55%;
    padding:18px 0;
    margin: 30px 0 145px 0;
}
.color-pallete{
    padding-bottom: 100px;
}
.color-boxes-main{
    display: flex;
    gap: 20px;
    align-items: center;
    margin-bottom: 10px;
}
.color-boxes-main P{
    color: #020659;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 250% */
    margin-bottom: 0;
}
.color-box{
    width: 70px;
    height: 70px;
}
.lets-chat{
    padding: 80px 0;
    background: #F6F7FF;
}
.lets-chat h2{
    color: #445765;
    font-family: "Inter";
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 72px; /* 150% */
}
.lets-chat h2 a{
    color: #020659;
    font-family: "Inter";
    font-size: 36px;
    font-style: italic;
    font-weight: 700;
    line-height: 72px; /* 200% */
    text-decoration-line: underline;
}
.project-summary{
    margin-bottom: 100px;
}
.project-summary h3,
.clientWork h3{
    color: #020659 !important;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 142.857% */
}
.project-about-bg{
    background: radial-gradient(48.68% 64.48% at 50% -3.1%, #21A497 0%, #046A6E 100%);
    padding: 0 42px 42px 42px;
    margin-top: 100px;
}
.project-about-bg img{
    margin-top: -100px;
}
.about-project p{
    opacity: .7;
}
.problem-solution{
    margin: 50px 0 98px 0;
}
.problem-solution p,
.problem-solution li{
    font-family: 'sofia-pro';
    opacity: .7; 
}
.problem{
    margin-bottom: 50px;
}
.problem-solution .images-preview{
    margin-top: 60px;
}
.project-imgs{
    border-radius: 34px !important;
    border: 8px solid #DBDBDB;
    box-shadow: 0px 2.76726px 2.21381px 0px rgba(0, 0, 0, 0.29), 0px 6.6501px 5.32008px 0px rgba(0, 0, 0, 0.21), 0px 12.52155px 10.01724px 0px rgba(0, 0, 0, 0.17), 0px 22.33631px 17.86905px 0px rgba(0, 0, 0, 0.14), 0px 41.77761px 33.42209px 0px rgba(0, 0, 0, 0.12), 0px 100px 80px 0px rgba(0, 0, 0, 0.08);
    margin-bottom: 100px;
}
.casedetail-project-heading{
    margin-bottom: 34px;
    color: #020659;
    font-family: "Inter";
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 142.857% */
}

/* contact page css */
.map-pin{
    position: absolute;
    right: 15%;
    top: 19%;
}
.map-pin .address-box{
    text-align: left;
    background-color: #fff;
    color: #000;
    padding: 12px 16px;
    box-shadow: 0px 16px 24px rgba(30, 30, 30, 0.08);
    margin-bottom: 20px;
    border-radius: 8px;
}
.map-pin .address-box .small{
    font-size: 12px !important;
    opacity: .5;
    line-height: 1.7;
    display: block;
}
.support-btn{
    padding: 12px 20px;
    width: fit-content;
    margin: 0 auto;
    transition: all .3s ease-in-out;
    border-radius: 0 !important;
}
.contect-us{
    padding: 100px 115px 100px 115px;
    background-image: url('../images/light-blue-bg.svg');
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
}
.contact-us-form{
    padding: 56px 48px 140px 48px;
    border-radius: 30px;
    border: 1px solid #CED1D8;
    background: #FFF;
    box-shadow: 0px 2.767256498336792px 2.2138051986694336px 0px rgba(0, 0, 0, 0.02), 0px 6.650102138519287px 5.32008171081543px 0px rgba(0, 0, 0, 0.03), 0px 12.521552085876465px 10.017241477966309px 0px rgba(0, 0, 0, 0.04), 0px 22.3363094329834px 17.869047164916992px 0px rgba(0, 0, 0, 0.04), 0px 41.777610778808594px 33.422088623046875px 0px rgba(0, 0, 0, 0.05), 0px 100px 80px 0px rgba(0, 0, 0, 0.07);
    margin-right: -220px;
    z-index: 1;
    position: relative;
}
.contact-us-form h3{
    line-height: 72px; /* 200% */
}
.contact-us-form p{
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 166.667% */
}
.contact-us-form p span{
    font-weight: 500;
}
.contact-us-form .form-control{
    background: #F2F2F2;
    border: 1px solid #EDEDED;
    padding: 10px 14px;
    border-radius: 8px;
    color: var(--Neutral-60, #7E8492);
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    resize: none;
}
.contact-us-form .form-control::placeholder{
    font-family: 'Inter';
}
.contact-us-form label{
    margin-bottom: 6px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
}
.contact-us-form .contact-submit{
    font-family: 'Inter';
    font-size: 23.146px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.contact-us-form .contact-submit svg{
    width: 27px;
    height: 23px;
}
.contact-us-right img{
    border-radius: 30px;
    height: 1060px;
    object-fit: cover;
}
/* contact page end */


.banner-animate{
    animation: up-down2 1.5s 1s infinite alternate ease-in-out;
}
.banner-animate2{
    animation: up-down 1.2s 1s infinite alternate ease-in-out;
}
.top-btn{
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #fff;
    background-color: transparent !important;
    color: #3484B5 !important;
    z-index: 10;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #3484B5;
    border-radius: 50%;
}
.top-btn:hover{
    background-color: #445765 !important;
    border-color: #445765 !important;
}
.top-btn:hover svg path{
    stroke: #fff;
}
@keyframes up-down2 {
    0%{ transform: translateY(-15px); }
    100%{ transform: translateY(0px); }
}
@keyframes up-down {
    0%{ transform: translateY(0px); }
    100%{ transform: translateY(-15px); }
}
.navbar-brand-white{
    display: none;
}
.header-absolute{
    position: absolute !important;
    z-index: 1;
    width: 100%;
    left: 0;
    top: 0;
    padding: 36px 160px 0 160px;
}
.navbar{
    transition: none !important;
}
.header-absolute .nav-item .nav-link{
    color: #fff !important;
}
.header-absolute .navbar-brand svg path {
    stroke: #fff;
    fill: #fff;
}
.header-absolute .navbar-nav .nav-link span svg path{
    stroke: #FFF !important;
}
.header-absolute .navbar-toggler{
    border: 1px solid #fff !important;
}
.header-absolute .navbar-toggler span{
    filter: brightness(7);
}
.header-absolute .navbar-collapse .start-btn{
    color: #fff !important;
}
.header-absolute .navbar-collapse .start-btn svg path{
    stroke: #fff !important;
}
.header-absolute .navbar-brand{
    display: none;
}
.header-absolute .navbar-brand-white{
    display: block;
}

.navbar-toggler:focus{
    box-shadow: none !important;
}
.mainProjectBox{
    margin-bottom: 60px;
    display: block;
    transition: all .3s ease-in-out;
}
.mainProjectBox.static{
    display: block;
}

/* modal css */
.modalBackround{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .7) ;
    z-index: 98;
}
.modalMain{
    position: fixed;
    transition: all .3s ease-in-out;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    margin: 0 auto;
    width: 50%;
    max-width: 1000px;
}
.modalMain.logo{
    width: 100%;
    max-width: 100%;
}
.modalMain .modalclose img{
    filter: invert(14) brightness(10.5);
}
.modalMain .modalclose{
    background-color: #8f8f8f;
    border-radius: 5px;
    position: absolute;
    right: 30px;
    top: 30px;
    border: 0 !important;
    /* background: none; */
}
.modalMain .modalclose:hover{
    background-color: #3484B5;
}
.modalMain.logo .modalclose{
    top: 0;
}
.project-category-menu{
    margin: 160px auto 87px auto;
    max-width: 610px;
}
.project-category-menu ul{
    gap: 26px;
}
.project-category-menu ul li p{
    color: #445765;
    text-align: center;
    font-family: "Inter";
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 27.429px; /* 171.429% */
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    transition: all .3s ease-in-out;
    border-bottom: 3px solid transparent;
}
.project-category-menu ul li p.active{
    color: #3484B5;
    border-color: #3484B5;
    padding-bottom: 2px;
    font-weight: 600;
}
.caseDetail-banner{
    padding: 308px 0 290px 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, .3);
}
.caseDetail-banner.white{
    position: relative;
}
.caseDetail-banner.white h1,
.caseDetail-banner.white p{
    position: relative;
}
.caseDetail-banner.white::before{
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .2);
    position: absolute;
    top: 0;
    left: 0; 
}
.caseDetail-banner .row.center{
    justify-content: center;
    text-align: center;
}
.caseDetail-banner .row.left{
    justify-content: start;
}
.caseDetail-banner .row.right{
    justify-content: end;
}
.caseDetail-banner h1{
    color: #FFF;
    font-family: "Inter";
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: 72px; /* 112.5% */
    margin-bottom: 20px;
}
.caseDetail-banner p{
    margin-bottom: 0px;
    color: #FFF;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 150% */
}
.clientWork{
    padding: 100px 0;
}
/* privacy page start */
.privacy-main h1{
    color: #445765;
    font-family: "Inter";
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 72px; /* 150% */
    padding-bottom: 49px;
    border-bottom: 9.6px solid #66ADD9;
}
.privacy-content-holder{
    padding: 40px 0;
    border-bottom: 1.6px solid rgba(0, 0, 0, 0.10);;
    position: relative;
}
.privacy-content-holder p,
.privacy-content-holder li{
    color: #859099;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 180% */
}
.privacy-content-holder p span{
    color: #020659;
}
.privacy-content-holder h2{
    color: #020659;
    font-family: "Inter";
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 72px; /* 200% */
    margin-bottom: 20px;
}
.privacy-content-holder ul{
    margin-top: 30px;
}
.privacy-content-holder::before{
    width: 96px;
    height: 3px;
    background-color: #B145BF;
    content: '';
    position: absolute;
    top: -2px;
    left: 0;
}
.privacy-content-holder.first::before{
    display: none;
}
/* privacy page end */

.mainProjects{
    margin-bottom: 40px;
    transition: unset !important ;
}
.customer-counter h2,
.customer-counter p{
    margin-bottom: 0;    
}
.map-section{
    margin-top: 90px;
}
.contact-list h4{
    color: #445765;
    text-align: center;
    font-family: 'Inter';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
    letter-spacing: 0.2px;
    margin-bottom: 12px;
}
.contact-list p{
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}
.contact-list a{
    font-weight: 700;
    line-height: 27.2px;
}

/* CountryDropdown.css */
.country-dropdown {
    position: relative;
    width: 60px;
    z-index: 15;
}
/* .country-dropdown >div{
    height: 45px;
} */
.css-13cymwt-control,
.css-t3ipsp-control{
    height: 45px !important;
    border: 0 !important;
    background-color: transparent !important;
    box-shadow: unset !important;
    color: rgba(68, 87, 101, 1) !important;
}
.css-1xc3v61-indicatorContainer,
.css-15lsz6c-indicatorContainer{
    padding: 0 2px 0 0 !important;
}
.css-1fdsijx-ValueContainer{
    padding: 0 10px !important;
    padding-right: 0 !important;
}
.css-1u9des2-indicatorSeparator{
    display: none !important;
}
.css-1dimb5e-singleValue{
    margin: 0px !important;
}
  
/* Styling for react-select component */
.react-select__control {
    border: none;
    border-radius: 0;
    box-shadow: none;
}
  
.react-select__menu {
    border-radius: 0;
    margin-top: 0;
}
  
.react-select__option:hover {
    background-color: #f0f0f0; /* Hover background color */
}
  
.react-select__option--is-selected {
    background-color: #3498db; /* Selected option background color */
    color: #fff; /* Selected option text color */
}
  
.react-select__indicator-separator {
    display: none;
}
  
.react-select__dropdown-indicator {
    color: #3498db; /* Dropdown indicator color */
}
  
/* Custom styling for the dropdown arrow */
.dropdown-header::after {
    content: '\25BC'; /* Unicode arrow-down character */
    float: right;
    margin-left: 8px;
    font-size: 12px;
}
  
.country-box{
    position: relative;
}
.country-box .country-dropdown{
    position: absolute;
}
.country-box .form-control{
    padding-left: 62px;
}
/* responsive */
@media (max-width:1800px) {
    .industry .industry-boxes .boxes{
        width: 180px;
        height: 180px;
    }
}
@media (max-width:1750px) {
    .banner-heading{
        font-size: 55px;
        line-height: 80px;
    }
    .container-fluid {
        padding: 0 100px;
    }
    .footer-left .row{
        margin-left: 50px !important;
    }
}
@media (max-width:1700px) {
    footer .footer-list-main{
        padding-left: 50px !important;
    }
    .footer-list-main .footer-list ul li a{
        font-size: 14px;
        letter-spacing: .33px;
    }
}
@media (max-width: 1600px) {
    /* .footer-list-main .footer-list ul li a:hover{
        padding-left: 12px;
    } */
    .container-fluid {
        padding: 0 70px;
    }
    .header-absolute {
        padding: 36px 70px 0;
    }
    .navbar-brand img,
    .navbar-brand-white img{
        width: 190px;
    }
    .client-industries{
        width: 70%;
    }
    .featured-services .card{
        padding: 55px 20px;
    }
    .navbar-nav .nav-link{
        padding: 8px 8px !important;
    }
    .contect-us{
        padding: 100px 40px 100px 40px;
    }
    .footer-right{
        padding-left: 60px;
    }
    .featured-services .services-card-main{
        max-width: calc(33% - 10px);
        width: 100%;
    }
    /* footer .footer-list-main{
        padding-left: inherit !important;
    } */
    .scrol-btn{
        margin-top: 80px;
    }
    .custom-solutions .custom-solutions-boxes-main .custom-solutions-boxes{
        width: 160px;
        height: 160px;
    }
    .navbar-nav .nav-item .nav-link{
        min-width: 100px;
    }
    .blockchain-dev-opt .dev-opt-mian h4{
        font-size: 20px;
        line-height: normal;
    }
    .logo-modal .main-img img,
    .logo-modal .logo-image-preview{
        max-width: 1000px;
        margin-bottom: 0;
        max-height: 470px;
    }
    .logo-modal .logo-image-preview img{
        max-width: 220px;
        max-height: 180px;
    }
}
@media (max-width:1500px) {
    .industry .industry-boxes .boxes{
        width: 160px;
        height: 160px;
    }
    .industry .industry-boxes .boxes img{
        max-width: 51px;
        max-height: 51px;
    }
    .industry .industry-boxes .boxes h4{
        font-size: 20px;
    }
    .testimonial .item small{
        font-size: 16px;
    }
    h2{
        font-size: 38px;
    }
    p{
        font-size: 18px !important;
        line-height: 30px !important;
    }
    .caseDetail-banner {
        padding: 230px 0 230px 0;
    }  
      
}
@media (max-width:1400px) {
    .working-with{
        margin: 100px 0;
    }
    .scrol-btn{
        margin-top: 80px;
    }
    .navbar-nav .nav-link{
        font-size: 15px;
    }
    .banner-card-img{
        max-width: 180px;
    }
    .start-btn.main-header-btn{
        padding: 10px !important;
    }
    .nav-item.dropdown .dropdown-menu-content .dropdown-item{
        font-size: 14px;
    }
    .featured-services .services-img img{
        max-width: 700px;
    }
    .quote-img img{
        width: 42px !important;
    }
    .working-with p{
        font-size: 20px;
    }
    .footer-left .row{
        margin-left: 20px !important;
    }
    footer .footer-list-main{
        padding-left: 32px !important;
    }
    .footer-list-main .footer-list ul li a::before{
        left: -20px;
    }
    .footer-right{
        padding-left: 0;
    }
    .about-us-text{
        margin-left: 0px;
    }
    .logo-modal .modal-nav{
        width: 50px;
        height: 50px;
    }
    .logo-modal .logo-image-preview,
    .logo-modal .main-img img{
        max-width: 800px;
        max-height: 370px;
        justify-content: center;
        gap: 20px;
    }
    .logo-modal .main-img img{
        margin-bottom: 0;
    }
    .logo-modal .logo-image-preview img{
        max-width: 180px;
        height: 150px;
    }
    .navbar-nav .nav-item .nav-link{
        min-width: 90px;
    }
    .custom-solutions .custom-solutions-boxes-main{
        flex-wrap: wrap;
    }
    .web-process-count-main::before {
        width: 86%;
    }
    .web-working-process .web-process-count-main .web-process-count p{
        font-size: 18px !important;
    }
    .footer-right .contect-p{
        font-size: 14px !important;
    }
    .mainProjects{
        margin-bottom: 0px;
    }
    .featured-services .card span p{
        font-size: 18px !important;
    }
    .project {
        padding-top: 0px;
    }
    .testimonial{
        padding-top: 0;
    }
    .contact-left{
        padding: 60px 30px;
    }
    .caseDetail-banner {
        padding: 200px 0 200px 0;
    } 
    .service-spec-box{
        padding: 25px;
    }
    .service-spec h4{
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 20px !important;
    }
}
@media (max-width:1300px) {
    .navbar-collapse .header-btn {
        padding: 10px;
    }
    .navbar-brand img,
    .navbar-brand-white img{
        width: 170px;
    }
    .contact-left{
        padding:40px 20px;
    }
    .contact-right{
        padding:40px 30px;
    }
    .product-graph .product-img{
        width: 110px;
        height: 110px;
    }
    .scrol-btn{
        margin-top: 60px;
    }
    .p-20px{
        padding: 16px !important;
    }
    .industry .industry-boxes .boxes{
        width: 140px;
        height: 140px;
    }
    .industry .industry-boxes .boxes img{
        max-width: 41px;
        max-height: 41px;
    }
    .industry .industry-boxes .boxes h4{
        font-size: 18px;
    }
    .about-us-text p{
        margin-bottom: 24px;
    }
}
@media (max-width: 1200px) {
    .caseDetail-banner {
        padding: 180px 0 180px 0;
    } 
    .container-fluid{
        padding: 0 50px;
    }
    .navbar-collapse.collapse:not(.show) {
        display: block !important;
        opacity: 0;
        /* transform: translate(-50%); */
        transform: scale(0.5);
        overflow: hidden;
        position: absolute;
        left: -100%;
        transition: all .3s ease-in-out;
    }
    .navbar-collapse{
        text-align: start !important;
        padding: 20px 0;
    }
    .navbar li.dropdown{
        text-align: start;
    }
    .navbar li.dropdown .dropdown-menu-content{
        background: transparent;
        box-shadow: none;
        border-top: 0;
        position: static;
        display: none;
        visibility: visible;
        opacity: 1;
        scale: 1;
    }
    .navbar-nav{
        align-items: start !important;
        text-align: center;
    }
    .banner-heading{
        font-size: 45px;
        line-height: 80px;
    }
    h2{
        font-size: 40px;
        line-height: 52px;
    }
    .footer-vertical-text {
        writing-mode: horizontal-tb;
        left: 0;
        top: 0;
        width: 100%;
        height: auto;
    }
    .contect-us{
        padding: 100px 20px 100px 20px;
    }
    .contact-us-form{
        margin-right: 0px;
    }
    .footer-right{
        padding-left: 12px;
    }
    .contact-left-main{
        margin-right: -40px;
    }
    .project .project-boxes-inner{
        padding: 20px;
    }
    .footer-left .row{
        margin-left: 0 !important;
    }
    .service-spec h4{
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 20px !important;
    }
    .service-spec h2{
        font-size: 30px !important;
        margin-bottom: 20px !important;
        line-height: 40px;
    }
    .blockchain-dev-opt .dev-opt-mian{
        padding: 15px 30px;
    }
    .blockchain-dev-opt .dev-opt-mian h4{
        font-size: 18px;
    }
    .dev-count .count-boxes{
        flex-direction: column;
        padding: 12px 5px;
    }
    .dev-count .count-boxes h2{
        font-size: 32px;
    }
    .dev-count .count-boxes p{
        font-size: 14px !important;
        text-align: center;
    }
    .dev-count .count-boxes p{
        max-width: 100%;
    }
    .service-process .process-boxes{
        padding: 30px 30px;
    }
    .footer-list-main.services-list{
        border-left: 0 !important;
        padding-left: 0 !important;
    }
    .header-absolute .nav-link.service-nav ~ ul li a {
        color: #fff !important;
    } 
    .web-working-process .web-process-count-main .web-process-count p{
        font-size: 16px !important;
    }
    .header-absolute .navbar-collapse{
        background-color: #292c32;
        width: 100%;
        padding: 30px 10px;
    }
    .navbar-nav .nav-item .nav-link{
        text-align: center;
    }
    .navbar-nav .nav-item .nav-link.service-nav{
        width: fit-content;
    }
    .team-holder .team-holder-img{
        width: 250px;
        height: 250px;
    }
    .team-holder .team-ovelay {
        width: 50px;
        height: 50px;
    }
    .contact-us-right img{
        height: auto;
    }
    .contact-us-form {
        padding: 56px 48px 48px 48px;
    }
    .caseDetail-banner h1{
        font-size: 50px;
        line-height: 60px;
    }
    .logo-modal .logo-image-preview, .logo-modal .main-img img{
        max-width: 600px;
        max-height: 300px;
    }
    .logo-modal .logo-image-preview img {
        max-width: 135px;
        height: 100px;
    }
}
@media (max-width:1100px) {
    .industry .industry-boxes{
        flex-wrap: wrap;
    }
}
@media (max-width: 1024px) {
    .quote-img img{
        width: 35px !important;
    }
    .featured-services .services-card-main{
        max-width: calc(50% - 10px);
        width: 100%;
    }
    .caseDetail-banner {
        padding: 150px 0 90px 0;
    }
    .caseDetail-banner h1{
        font-size: 45px;
        line-height: 50px;
    }
}
@media (max-width: 991px) {
    .about-us-text{
        margin-left: 0;
    }
    .contact-left-main{
        margin-right: 0;
        margin-bottom: 30px;
    }
    #testimonial-slides .item .client-image{
        max-width: 500px !important;
    }
    .footer-left .row{
        margin-left: 0;
    }
    p{
        font-size: 18px !important;
    }
    .footer-right small{
        margin-bottom: 80px;
    }
    .absolute-img {
        top: 50%;
        right: 0;
    }
    .banner-card-img{
        display: none;
    }
    .cloud-services .cloud-boxes.one,
    .cloud-services .cloud-boxes.three,
    .cloud-services .cloud-boxes.two{
        border-bottom: 0;
    }
    .cloud-services .cloud-boxes.one,
    .cloud-services .cloud-boxes.two,
    .cloud-services .cloud-boxes.four,
    .cloud-services .cloud-boxes.five{
        border-right: 0;
    }
    footer .footer-list-main{
        border-left: 0 !important;
        padding-left: inherit !important;
    }
    .logo-modal .logo-image-preview img {
        max-width: 160px;
        height: 80px;
    }
    .web-process-count-main::before{
        width: 92%;
        top: 40%;
    }
    .footer-list-main.footer-detail{
        padding-left: 0 !important;
    }
    .contact-left .location {
        margin-top: 100px;
    }
    .team-holder .team-holder-img{
        width: 200px;
        height: 200px;
    }
    .team-holder h4{
        font-size: 20px;
    }
    .team-holder .team-ovelay {
        width: 40px;
        height: 40px;
        top: 20px;
    }
    .team-holder .team-role h4{
        font-size: 22px;
    }

}
@media (max-width: 767px) {
    .container-fluid{
        padding: 0 20px;
    }
    .caseDetail-banner{
        background-image: none !important;
        text-align: center ;
    }
    .header-absolute{
        padding: 36px 20px 0;
    }
    p{
        font-size: 16px !important;
        line-height: 30px !important;
    }
    .footer-right small{
        margin-bottom: 50px;
    }
    .footer-bottom{
        margin-top: 80px;
    }
    .about-img{  
        width: 90%;
        margin: 0 auto;
    }
    h2{
        font-size: 35px !important;
        line-height: 55px !important;
    }
    .contact-left .location{
        margin-top: 90px;
    }
    h3 {
        font-size: 32px !important;
        line-height: 37px !important;
    }
    small{
        font-size: 14px;
    }
    .mainProjectBox{
        width: 100%;
    }
    .mainProjectBox:nth-of-type(odd){
        margin-top: 0;
    }
    .mainProjects{
        margin-top: 0;
    }
    .project .project-boxes-inner{
        padding: 15px 10px;
    }
    .team .team-holder-main{
        flex-wrap: wrap;
        justify-content: space-around;
    }
    .caseDetail-banner h1{
        font-size: 45px;
        line-height: 60px;
    }
    .caseDetail-banner{
        padding: 200px 0;
    }
    .service-process{
        padding: 100px 0 150px 0;
    }
    .project-category-menu{
        margin: 100px auto 80px auto;
    }
    .project-category-menu ul{
        justify-content: center !important;
        gap: 20px;
    }
    .project-category-menu ul li a.active{
        padding-bottom: 10px;
    }
    .featured-services .services-card-main{
        max-width: 100%;
        width: 100%;
    }
    footer .footer-list-main{
        padding-left: inherit !important;
        border-left: 0 !important;
    }
    .footer-left .row{
        margin-left: 0 !important;
    }
    .dev-portfolio #devportfolio-slides .owl-nav button{
        width: 30px;
        height: 30px;
    }
    .dev-portfolio #devportfolio-slides .owl-nav button span{
        font-size: 36px;
        line-height: 13px;
    }
    .dev-portfolio #devportfolio-slides .owl-nav{
        top: 200px;
    }
    .contact-left .location{
        margin-top: 35px;
    }
    .contact-left .location p:last-child{
        margin-bottom: 0;
    }
    .quote-img img{
        width: 32px !important;
    }
    .testimonial .item small{
        font-size: 14px;
    }
    .logo-image-preview{
        flex-wrap: wrap;
    }
    .logo-modal .modal-nav{
        width: 30px;
        height: 30px;
    }
    .logo-modal .modal-nav img{
        width: 20px;
    }
    .logo-modal .main-img img{
        margin-bottom: 0;
    }
    .modalMain.logo .modalclose {
        top: -10px;
        right: 0;
    }
    .modalMain.logo .modalclose img{
        width: 30px;
        height: 30px;
    }
    .about-banner .about-counter{
        border-right: 0;
    }
    .dev-count{
        padding: 50px 0px;
    }
    .service-spec{
        padding: 80px 0;
    }
    .leaders{
        padding: 200px 0;
    }
    .web-working-process .web-process-count-main .web-process-count small{
        font-size: 20px;
    }
    .web-process-count-main::before{
        display: none;
    }
    .web-working-process .web-process-count-main .web-process-count h2::before{
        display: none;
    }
    .web-working-process .web-process-count-main {
        flex-wrap: wrap;
        justify-content: center;
        gap: 30px;
    }
    .project-imgs{
        margin-bottom: 50px;
    }
    .casedetail-project-heading{
        margin-bottom: 25px;
    }
    .footer-list-main.list-icon{
        padding-left: 0 !important;
        margin-top: 50px;
    }
    .footer-list-main.footer-detail .social-icon{
        margin-top: 40px !important;
    }
    .team-holder .team-holder-img{
        width: 160px;
        height: 160px;
    }
    .team-holder .team-ovelay {
        width: 24px;
        height: 24px;
    }
    .contact-us-form .contact-submit{
        font-size: 18px;
    }
    .contact-us-form .contact-submit svg{
        width: 20px;
        height: 18px;
    }
    .contact-us-form {
        padding: 32px;
    }
}
@media (max-width: 500px) {
    small{
        font-size: 14px;
    }
    p{
        font-size: 14px !important;
        line-height: 25px !important;
    }
    .banner-left::before{
        display: none;
    }
    .footer-bottom{
        margin-top: 50px;
        flex-wrap: wrap;
    }
    .footer-bottom .social-icon{
        margin-bottom: 20px;
    }
    footer{
        padding-top: 110px;
    }
    .video-background {
        top: -21px;
        left: -22px;
        border-radius: 30px 30px 30px 150px;
    }
    .about-img img{
        border-radius: 0px 0px 0px 150px;
    }
    h2{
        line-height: 40px !important;
        font-size: 30px !important;
    }
    .contact-left{
        padding:30px 0px;
    }
    .contact-right{
        padding: 15px 10px;
        border-radius: 12px;
    }
    .contact-left .location{
        margin-top: 50px;
    }
    h3 {
        font-size: 26px !important;
        line-height: 30px !important;
    }
    .contact-us .form-control{
        padding: 16px 10px;
        font-size: 14px;
    }
    .products .product-graph{
        margin:80px 0 50px 0 ;
    }
    .banner-heading{
        font-size: 40px;
        line-height: 50px;
    }
    .navbar{
        margin-bottom: 50px;
    }
    .absolute-img {
        top: 70%;
        right: 0;
    }
    .contact-us-form{
        padding: 30px 20px;
    }
    .contect-us{
        padding: 100px 0px;
    }
    .steps-img img{
        width: 80px;
    }
    .play-btn img{
        width: 40px;
    }
    .project-boxes-inner a{
        font-size: 20px;
        line-height: 30px !important;
    }
    .project .project-boxes-inner{
        border-radius: 0 0 15px 15px;
    }
    .privacy-content-holder h2{
        font-size: 26px;
        line-height: 50px;
    }
    .privacy-main h1{
        font-size: 32px;
        line-height: 50px;
    }
    .service-spec-box{
        padding: 20px;
    }
    .service-process{
        padding: 70px 0px;
    }
    .servce-banner-content {
        padding: 45% 0;
        position: relative;
    }
    .project-category-menu{
        margin: 40px auto 40px auto;
    }
    .banner-card-img{
        display: none;
    }
    .tech-weUse .tab-btn-main .tab-btn{
        font-size: 19px;
        line-height: 32px;
    }
    .logo-modal .logo-image-preview{
        gap: 10px;
    }
    .logo-modal .logo-image-preview img{
        max-width: 80px;
        height: 50px;
    }
    .logo-modal .modal-nav{
        width: 20px;
        height: 20px;
        background: transparent;
        box-shadow: none;
    }
    #testimonial-slides .owl-dots .owl-dot span{
        width: 10px;
        height: 10px;
    }
    #testimonial-slides .owl-dots .owl-dot.active span{
        width: 30px;
    }
    .service-spec h2{
        font-size: 24px !important;
        margin-bottom: 20px !important;
        line-height: 35px;
    }
    .top-btn{
        width: 30px;
        height: 30px;
    }
    .dev-portfolio{
        padding: 100px 0;
    }
    .leaders{
        padding: 100px 0;
    }
    .mainProjectBox{
        margin-bottom: 30px;
    }
    .project{
        padding-top: 20px;
    }
    .contact-right button[type=submit]{
        padding: 10px 15px;
    }
}
@media (max-width: 420px) {
    h2{
        line-height: 32px !important;
        font-size: 28px !important;
        text-wrap: balance;
    }
    h4{
        font-size: 20px !important;
        line-height: 30px !important;
    }
    h3{
        font-size: 22px;
    }
    .video-background {
        top: -18px;
        left: -18px;
        border-radius: 30px 30px 30px 100px;
    }
    .about-img img{
        border-radius: 0px 0px 0px 100px;
    }
    .location p:nth-child(2){
        word-wrap: break-word;
    }
    .contact-left .location p{
        margin-bottom: 20px;
    }
    button{
        font-size: 13px !important;
    }
    .p-20px{
        padding: 13px !important;
    }
    .banner-animate2{
        width: 140px;
    }
    .banner-heading {
        font-size: 29px;
        line-height: 50px;
    }
    .about-us .gradiant-text.about{
        font-size: 24px;
    }
    .project-about-bg{
        margin-top: 50px;
    }
    .project-about-bg img{
        margin-top: -50px;
    }
    .location p img{
        width: 25px;
        height: 25px;
    }
    .caseDetail-banner h1{
        font-size: 35px;
        line-height: 40px;
    }
    .caseDetail-banner{
        padding: 150px 0;
    }
    .vision .main-heading h2{
        font-size: 22px !important;
    }
    .map-pin .address-box{
        padding: 6px 9px;
        margin-bottom: 8px;
    }
    .map-pin {
        right: 10%;
        top: 5%;
    }
    .contact-us-form input[type=submit]{
        font-size: 18px;
        padding: 8px 0;
    }
    .industry .industry-boxes .boxes {
        width: 120px;
        height: 120px;
    }
    .industry .industry-boxes .boxes h4{
        font-size: 16px !important;
    }
    .industry .industry-boxes{
        justify-content: center;
        gap: 10px;
    }
    .product-graph .product-img img{
        width: 50px;
        height: 50px;
    }
    .logo-modal .logo-image-preview img{
        max-width: 60px;
        height: 40px;
    }
    .custom-solutions .custom-solutions-boxes-main .custom-solutions-boxes{
        width: 130px;
        height: 130px;
    }
    .custom-solutions .custom-solutions-boxes-main{
        gap: 10px;
    }
    .tech-weUse .tab-contents .tech-main .tech-boxes{
        max-width: 100px;
        margin: 0 auto;
    }
    .tech-weUse .tab-contents .tech-main .tech-boxes-img{
        width: 100px;
        height: 100px;
    }
}
@media (max-width : 382px) {
    .watch-btn{
        margin-top: 15px;
    }
}
@media (max-width:350px) {
    .dev-portfolio #devportfolio-slides .owl-nav{
        top: 220px;
    }
}
@media (min-width: 300px){
    .contect-p{
        font-size: 16px !important;
    }
    .footer-right p{
        font-size: 16px !important;
    }
}